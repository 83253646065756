body, h1 {
  margin: 0;
  padding: 0;
}

body {
  color: #5072a7;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

/*# sourceMappingURL=index.06e02bc2.css.map */
